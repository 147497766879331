<template>
  <Main>
    <template v-slot:content>
      <div v-if="project != null" class="project">
        <div v-if="projects.length > 1" class="project__order-list container">
          <div v-for="(item, index) in projects" :key="index" class="project__order-list-item">
            <div :class="['project__order-item', index + 1 <= step && 'active']">
              ORDER {{ index + 1 }}
            </div>
            <LineDotIcon v-if="projects.length != index + 1" />
          </div>
        </div>
        <div
          v-if="styles.length && project.service_type.value != 'remove_furniture'"
          class="container"
        >
          <div class="project__loader-title">Choose style</div>

          <div class="project__loader-slider">
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                @click="handleChangeStyleProject(item.name)"
                v-for="item in styles"
                :key="item.name"
                class="project__loader-slider-item"
              >
                <img :src="item.image_url" :alt="item.title" />
                <div class="project__loader-slide-text">
                  {{ item.title }}
                </div>
                <div
                  v-if="item.name == project.style.value"
                  class="project__loader-slide-check active"
                >
                  <CheckStyleIcon />
                </div>
                <div v-else class="project__loader-slide-check"></div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <div class="container">
          <div class="project__loader-title">Rooms</div>
        </div>

        <div v-for="item in project.rooms" :key="item.id" class="project__room-item">
          <div class="container">
            <div class="project__room-item-container">
              <div class="project__room-item-image">
                <img :src="item.image.url" alt="" />
              </div>

              <div class="project__room-item-types">
                <div class="project__room-item-title">Type of room</div>

                <div class="project__room-type-list">
                  <div v-for="type in roomTypes" :key="type.name" class="project__room-type-item">
                    <div
                      @click="handleChangeTypeRoom(type.name, item)"
                      :class="[
                        'project__room-type-item-check',
                        item.type.value == type.name && 'active',
                      ]"
                    >
                      <CheckStyleIcon />
                    </div>
                    {{ type.title }}
                  </div>
                </div>
              </div>

              <div class="project__room-item-desc">
                <div class="project__room-item-title">Write us your wishes</div>
                <div class="project__room-item-radio">
                  <label class="custom-radio__container">
                    <input
                      type="radio"
                      :name="`have_wishes_${item.id}`"
                      v-model="item.no_comments"
                      @change="handleChangeWishes(item, false)"
                      value="0"
                    />
                    <span class="custom-radio"></span>
                    <span class="custom-radio__text">
                      Tick the textfield and write their wishes
                    </span>
                  </label>
                </div>
                <div class="project__room-item-radio">
                  <label class="custom-radio__container">
                    <input
                      type="radio"
                      :name="`have_wishes_${item.id}`"
                      v-model="item.no_comments"
                      @change="handleChangeWishes(item, true)"
                      value="1"
                    />
                    <span class="custom-radio"></span>
                    <span class="custom-radio__text"> Leave it to us </span>
                  </label>
                </div>
                <div v-if="!item.no_comments" class="project__room-item-comment">
                  <textarea @change="handleChangeTextarea(item)" v-model="item.comments"></textarea>
                </div>
              </div>
            </div>
            <div
              v-if="
                project.service_type.value == 'remove_furniture' ||
                project.service_type.value == 'add_furniture'
              "
              class="project__room-special"
            >
              <div class="project__room-special-title">
                {{
                  project.service_type.value == "remove_furniture"
                    ? "Add furniture"
                    : "Remove furniture"
                }}
              </div>
              <div @click="handleChangeSpecial(item)" class="project__room-special-container">
                <div v-if="item.special_feature" class="project__room-special-check active">
                  <CheckStyleIcon />
                </div>
                <div v-else class="project__room-special-check"></div>
                <div class="project__room-special-text">
                  {{
                    project.service_type.value == "remove_furniture"
                      ? "Click here to Add new furniture  (+15€)"
                      : "Click here to remove the existing furniture in the rooms  (+15€)"
                  }}
                </div>
              </div>
            </div>
            <div
              class="project__room-item-style"
              v-if="
                (item.type.value == 'kitchen' || item.type.value == 'bathroom') &&
                styles.length &&
                project.service_type.value != 'remove_furniture'
              "
            >
              <div class="project__loader-title">Choose style for {{ item.type.title }}</div>

              <div class="project__loader-slider">
                <VueSlickCarousel :arrows="true" v-bind="settings">
                  <div
                    @click="handleChangeStyleRoom(item, elem.name)"
                    v-for="elem in styles"
                    :key="elem.name"
                    class="project__loader-slider-item"
                  >
                    <img :src="elem.image_url" :alt="elem.title" />

                    <div
                      v-if="elem.name == item.style.value"
                      class="project__loader-slide-check active"
                    >
                      <CheckStyleIcon />
                    </div>
                    <div v-else class="project__loader-slide-check"></div>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </div>

        <div v-if="floors.length && project.service_type.value == 'renovate'" class="container">
          <div class="project__loader-title">Type of the floor</div>

          <div class="project__loader-slider">
            <VueSlickCarousel :arrows="true" v-bind="settings">
              <div
                @click="handleChangeFloorProject(item.name)"
                v-for="item in floors"
                :key="item.name"
                class="project__loader-slider-item"
              >
                <img :src="item.image_url" :alt="item.title" />
                <div class="project__loader-slide-text">
                  {{ item.title }}
                </div>
                <div
                  v-if="item.name == project.floor_type.value"
                  class="project__loader-slide-check active"
                >
                  <CheckStyleIcon />
                </div>
                <div v-else class="project__loader-slide-check"></div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>

        <div class="project__loader-plan container">
          <div class="project__loader-title">You can upload examples for inspiration</div>

          <div class="project__loader-plan-item">
            <div
              :class="['upload-box', project.inspiration_image.url && 'active']"
              @dragover.prevent
              @drop.prevent="handleDropExample"
            >
              <input
                type="file"
                @change="handleFilesExample"
                ref="fileInputExample"
                style="display: none"
              />
              <div
                :class="['upload-box-content', project.inspiration_image.url && 'active']"
                @click="triggerFileInputExample"
              >
                <DownloadIcon />
                Drag your photo here
              </div>
            </div>
            <img v-if="project.inspiration_image.url" :src="project.inspiration_image.url" alt="" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="project-footer">
        <div class="project-footer__container container">
          <div class="project-footer__buttons">
            <div @click="handleBack" class="project-footer__button grey">Back</div>
            <div
              v-if="multi ? step != projects.length : false"
              @click="handleNextStep"
              class="project-footer__button black"
            >
              Next Step
            </div>
          </div>
          <div class="project-footer__buttons">
            <div v-if="project" class="project-footer__price">{{ project.price.amount }} €</div>
            <div
              v-if="multi ? step == projects.length : true"
              @click="handleToOrder()"
              class="project-footer__to-order"
            >
              To Order
            </div>
          </div>
        </div>
      </div>
      <FetchSpinnerModal v-if="loading" />
    </template>
  </Main>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";

import DownloadIcon from "~/assets/images/icons/download-icon.svg";
import CheckStyleIcon from "~/assets/images/icons/check-style.svg";
import LineDotIcon from "~/assets/images/icons/line-dot.svg";

export default {
  data() {
    return {
      project: null,
      loading: false,
      multi: false,
      step: 1,
      projects: [],
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: true,
        infinite: false,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: true,
            },
          },
          {
            breakpoint: 568,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },
      styles: [],
      floors: [],
      roomTypes: [],
    };
  },
  mounted() {
    this.getProject();
    this.getStyles();
    this.getFloors();
    this.getRoomTypes();

    if (this.$route.query.projects) {
      this.projects = this.$route.query.projects.split(",");
      this.multi = true;
      this.step = +this.projects.indexOf(this.$route.params.id) + 1;
    }
  },
  components: {
    VueSlickCarousel,
    Main,
    DownloadIcon,
    CheckStyleIcon,
    FetchSpinnerModal,
    LineDotIcon,
  },
  methods: {
    getProject() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getProjectItem(url)
          .then((res) => {
            const array = res;
            for (let i = 0; array.rooms.length > i; i++) {
              array.rooms[i].no_comments = array.rooms[i].no_comments ? 1 : 0;
            }
            this.project = array;
          })
          .catch((error) => {
            this.$router.push({
              path: ROUTE.HOME_PAGE,
            });
          })
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    handleChangeStyleProject(style) {
      this.loading = true;

      const url = `/${this.$route.params.id}`;
      const data = {
        service_type: this.project.service_type.value,
        style: style,
      };
      try {
        projectApi
          .updateProject(url, data)
          .then((res) => {
            this.getProject();
            this.loading = false;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    handleChangeFloorProject(floor) {
      this.loading = true;

      const url = `/${this.$route.params.id}`;
      const data = {
        floor_type: floor,
      };
      try {
        projectApi
          .updateProject(url, data)
          .then((res) => {
            this.getProject();
            this.loading = false;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    handleChangeStyleRoom(item, style) {
      this.loading = true;

      const url = `/${this.$route.params.id}/rooms/${item.id}`;
      const data = {
        type: item.type.value,
        style: style,
        comments: item.comments,
        no_comments: item.no_comments,
        special_feature: item.special_feature,
      };
      this.updateRoom(data, url);
    },

    handleChangeTypeRoom(type, item) {
      this.loading = true;

      const url = `/${this.$route.params.id}/rooms/${item.id}`;
      const data = {
        type: type,
        comments: item.comments,
        no_comments: item.no_comments,
        style: item.style.value,
        special_feature: item.special_feature,
      };
      this.updateRoom(data, url);
    },

    handleChangeTextarea(item) {
      this.loading = true;

      const url = `/${this.$route.params.id}/rooms/${item.id}`;
      const data = {
        comments: item.comments,
        no_comments: item.no_comments,
        type: item.type.value,
        style: item.style.value,
        special_feature: item.special_feature,
      };

      this.updateRoom(data, url);
    },

    handleChangeWishes(item, wishes) {
      this.loading = true;

      const url = `/${this.$route.params.id}/rooms/${item.id}`;
      const data = {
        comments: item.comments,
        no_comments: wishes,
        type: item.type.value,
        style: item.style.value,
        special_feature: item.special_feature,
      };

      this.updateRoom(data, url);
    },

    handleChangeSpecial(item) {
      this.loading = true;

      const url = `/${this.$route.params.id}/rooms/${item.id}`;
      const data = {
        comments: item.comments,
        no_comments: item.no_comments,
        type: item.type.value,
        style: item.style.value,
        special_feature: !item.special_feature,
      };

      this.updateRoom(data, url);
    },

    updateRoom(data, url) {
      try {
        projectApi
          .updateRoom(url, data)
          .then((res) => {
            this.getProject();
            this.loading = false;
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    handleToOrder() {
      this.loading = true;
      let data;
      if (this.multi) {
        data = {
          project_ids: this.projects,
        };
      } else {
        data = {
          project_ids: [this.$route.params.id],
        };
      }

      try {
        projectApi
          .createOrder(data)
          .then((res) => {
            console.log(res);

            this.$router.push({
              path: ROUTE.ORDERS.replace(":id", res.id),
            });
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    getStyles() {
      try {
        baseApi
          .getListStyles()
          .then((res) => {
            this.styles = res;
            console.log(res);
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    getFloors() {
      try {
        baseApi
          .getListFloors()
          .then((res) => {
            this.floors = res;
            console.log(res);
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    getRoomTypes() {
      try {
        baseApi
          .getListRoomTypes()
          .then((res) => {
            this.roomTypes = res;
            console.log(res);
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        console.log(error);
      }
    },

    handleBack() {
      if (this.multi) {
        let url = `?projects=`;
        for (let a = 0; this.projects.length > a; a++) {
          if (this.projects.length == a + 1) {
            url += `${this.projects[a]}`;
          } else {
            url += `${this.projects[a]},`;
          }
        }
        this.$router.push({
          path: ROUTE.PROJECT.replace(":id", this.$route.params.id) + url,
        });
      } else {
        this.$router.push({
          path: ROUTE.PROJECT.replace(":id", this.$route.params.id),
        });
      }
    },

    handleNextStep() {
      if (this.multi) {
        let url = `?projects=`;
        for (let a = 0; this.projects.length > a; a++) {
          if (this.projects.length == a + 1) {
            url += `${this.projects[a]}`;
          } else {
            url += `${this.projects[a]},`;
          }
        }
        this.$router.push({
          path: ROUTE.PROJECT.replace(":id", this.projects[this.step]) + url,
        });
      }
    },

    triggerFileInputExample() {
      this.$refs.fileInputExample.click();
    },

    async handleFilesExample(event) {
      this.loading = true;
      const file = event.target.files[0] || event.dataTransfer.files[0];

      const uploadExampleUrl = `/${this.$route.params.id}/inspiration-images`;

      const formData = new FormData();
      formData.append("image", file);

      if (this.project.plan_image) {
        await projectApi.deleteExampleImage(uploadExampleUrl);
      }

      try {
        await projectApi.uploadExampleImage(uploadExampleUrl, formData);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }

      await this.getProject();
      this.loading = false;
    },

    async handleDropExample(event) {
      this.loading = true;
      const file = event.dataTransfer.files[0];
      const uploadExampleUrl = `/${this.$route.params.id}/inspiration-images`;

      const formData = new FormData();
      formData.append("image", file);

      if (this.project.plan_image) {
        await projectApi.deleteExampleImage(uploadExampleUrl);
      }

      try {
        await projectApi.uploadExampleImage(uploadExampleUrl, formData);
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }

      await this.getProject();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.project {
  &__top {
    display: flex;
    padding: 25px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #d7d9dd;
  }

  &__room-item-style {
    padding-top: 30px;
  }

  &__room-item-radio {
    padding-bottom: 10px;
  }

  &__room-item-comment {
    textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      font-size: 14px;
      font-family: "Inter", sans-serif;
    }
  }

  &__loader-title {
    font-size: 20px;
    font-family: "Mirador";
    padding-bottom: 10px;
    font-weight: 600;
  }

  &__loader-subtext {
    font-size: 16px;
    color: #8e8e8e;

    span {
      color: #e2666d;
    }
  }

  &__top-item {
    margin-right: 50px;
  }

  &__loader-slider {
    padding: 20px 0;
  }

  &__loader-slider-item {
    aspect-ratio: 4/3;
    padding-right: 10px;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__loader-slide-text {
    font-size: 16px;
    font-family: "Mirador";
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 40px;
    background: rgba(#ffffff, 0.6);
  }

  &__loader-slide-check {
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #adadad;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      background: #000;

      svg {
        display: block;
      }
    }
  }

  &__room-special {
    padding-top: 30px;
  }

  &__room-special-title {
    font-size: 16px;
    font-weight: 600;
    font-family: "Mirador";
  }

  &__room-special-container {
    padding-top: 15px;
  }

  &__room-special-container {
    display: flex;
    align-items: center;
  }

  &__room-special-check {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #adadad;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      background: #000;

      svg {
        display: block;
      }
    }
  }

  &__loader-plan {
    padding: 20px 0;
    border-bottom: 1px solid #d7d9dd;
    border-top: 1px solid #d7d9dd;
  }

  &__loader-plan-item {
    width: calc((100% / 4) - 10px);
    aspect-ratio: 4/3;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100%);
      height: 100%;
      object-fit: cover;
    }
  }

  &__room-item-container {
    display: flex;
    justify-content: space-between;
  }

  &__room-item {
    padding: 20px 0 20px;

    &:nth-child(even) {
      background: #f9f9f9;
    }
  }

  &__room-item-image {
    width: 33%;

    img {
      width: 100%;
    }
  }

  &__room-item-types {
    width: 33%;
    padding: 0 50px;
  }

  &__room-item-title {
    font-family: "Mirador";
    font-weight: 600;
    padding-bottom: 15px;
  }

  &__room-type-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__room-type-item {
    width: 50%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__room-type-item-check {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #adadad;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;
    cursor: pointer;

    svg {
      display: none;
    }

    &.active {
      background: #000;

      svg {
        display: block;
      }
    }
  }

  &__room-item-desc {
    width: 33%;
  }

  &__order-list {
    padding: 20px 0 20px;
    display: flex;
  }

  &__order-list-item {
    padding-right: 20px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 20px;
    }
  }

  &__order-item {
    font-size: 24px;
    font-weight: 700;
    color: #191919;
    padding: 10px 30px;
    border-radius: 8px;
    background: #bec4d2;
    opacity: 0.5;

    &.active {
      opacity: 1;
    }
  }

  ::v-deep(.slick-prev),
  ::v-deep(.slick-next) {
    bottom: 0;
    opacity: 0.85;
    border-radius: 15px;
    width: 44px;
    height: 44px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    &:hover {
    }
  }
  ::v-deep(.slick-prev:before),
  ::v-deep(.slick-next:before) {
    display: none;
  }
  ::v-deep(.slick-prev.slick-disabled),
  ::v-deep(.slick-next.slick-disabled) {
    opacity: 0.5;
  }
  ::v-deep(.slick-prev) {
    left: -20px;
  }
  ::v-deep(.slick-next) {
    right: -20px;
  }
  ::v-deep(.slick-prev) {
    background-image: url("../../assets/images/icons/slick-prev.png");
    background-repeat: no-repeat;
    color: transparent;
    background-size: contain;
    background-position: center;
  }
  ::v-deep(.slick-next) {
    background-image: url("../../assets/images/icons/slick-next.png");
    background-repeat: no-repeat;
    color: transparent;
    background-size: contain;
    background-position: center;
  }
}
.custom-radio__container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

/* Создаем кастомный радиобатон */
.custom-radio {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2) inset;

  &__text {
    color: #000;
  }

  &__desc {
    color: #8e8e8e;
    padding-left: 10px;
  }
}

input[type="radio"]:disabled + .custom-radio__text {
  color: #8e8e8e;
}

input[type="radio"] {
  overflow: hidden;
  height: 0;
  width: 0;
  position: absolute;
}

/* Добавляем стиль для состояния "checked" */
input[type="radio"]:checked + .custom-radio::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000000;
}

.upload-box {
  border: 1px solid #d7d9dd;
  background: #f9f9f9;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  input {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
  }

  &.active:before {
    content: "";
    position: absolute;
    inset: 0;
    background: #000;
    opacity: 0.3;
    z-index: 1;
  }
}

.upload-box-content {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  font-weight: 700;

  &.active {
    color: #fff;

    svg {
      g {
        fill: #fff;
      }
    }
  }
}

.image-preview {
  width: 100%;
  height: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__delete {
    position: absolute;
    bottom: 0;
    right: 5px;
    cursor: pointer;
  }
}

.image-item .image-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.project-footer {
  padding: 30px 0;
  background: #f9f9f9;
  border-top: 1px solid #d7d9dd;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__to-order {
    padding: 10px 40px;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 20px;
    color: #fff;
    background: #191919;
    transition: all ease-in-out 0.5s;

    &:hover {
      background: #000;
      transition: all ease-in-out 0.5s;
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    padding: 10px 40px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 20px;

    &.grey {
      color: #191919;
      background: #dadee7;
      transition: all ease-in-out 0.5s;

      &:hover {
        background: #b1b5bd;
        transition: all ease-in-out 0.5s;
      }
    }

    &.black {
      color: #fff;
      background: #191919;
      transition: all ease-in-out 0.5s;

      &:hover {
        background: #000;
        transition: all ease-in-out 0.5s;
      }
    }
  }

  &__price {
    font-size: 34px;
    font-weight: 600;
    color: #000;
  }
}

@media (max-width: 1350px) {
  .project {
    &__loader-title {
      padding: 10px 0 0;
    }

    &__room-item-container {
      flex-wrap: wrap;
      justify-content: left;
    }

    &__room-item-image {
      width: 33%;
    }

    &__room-item-types {
      width: 45%;
      padding-top: 10px;
    }

    &__room-item-desc {
      width: 40%;
    }

    &__room-special {
      padding-top: 10px;
    }

    &__loader-plan-item {
      padding-top: 10px;
      width: calc(100% / 3 - 10px);
    }
  }
}

@media (max-width: 991px) {
  .project {
    &__loader-plan-item {
      width: calc(100% / 2 - 10px);
    }

    &__room-item-image {
      width: 40%;
    }

    &__room-item-types {
      width: 60%;
      padding-top: 10px;
    }

    &__room-item-desc {
      width: 65%;
    }
  }
}

@media (max-width: 568px) {
  .project {
    &__loader-plan-item {
      width: calc(100%);
    }

    &__room-item-image {
      width: 100%;
    }

    &__room-item-types {
      width: 100%;
      padding-top: 10px;
    }

    &__room-item-desc {
      width: 100%;
      padding-top: 10px;
    }

    &__room-item-types {
      padding: 10px 0 0;
    }
  }

  .project-footer {
    &__price {
      font-size: 20px;
      display: flex;
      align-items: center;
    }

    &__button {
      font-size: 14px;
      padding: 10px 30px;
    }

    &__to-order {
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}
</style>
